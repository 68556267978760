import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './modules/_shared/routes/user/login/login.component';
import { AuthGuardService } from './modules/_shared/services/auth-guard.service';
import { ReloadPageComponent } from './modules/_shared/routes/reload-page/reload-page.component';
import { DashboardComponent } from './modules/_shared/routes/dashboard/dashboard.component';
import { AppRoutes, DebugModule, NotAuthorizedComponent, PageNotFoundComponent } from '@hutsix/ngxh6';

const routes: AppRoutes = [
    { path: 'reload', component: ReloadPageComponent },
    { path: 'login', component: LoginComponent },
    {
        path: 'debug',
        canActivate: [AuthGuardService],
        data: { roles: ['ROLE_ADMIN'] },
        loadChildren: () => DebugModule,
    },
    {
        path: 'user-admin',
        loadChildren: () => import('./modules/user-admin/user-admin.module').then(m => m.UserAdminModule),
    },

    // { path: 'form/:slug', canActivate: [AuthGuardService], component: FormRouteComponent },
    // { path: 'form/:slug/:id', canActivate: [AuthGuardService], component: FormRouteComponent },
    // { path: '', canActivate: [AuthGuardService], component: DashboardComponent },

    {
        path: '',
        canActivate: [AuthGuardService],
        data: {},
        loadChildren: () => import('./modules/default/default.module').then(m => m.DefaultModule),
    },
    { path: 'unauthorised', canActivate: [AuthGuardService], component: NotAuthorizedComponent },
    { path: '**', canActivate: [AuthGuardService], component: PageNotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
