<ng-container *ngIf="form.view; else loadingOrError">
    <form #formRef="ngForm" class="w-100" (ngSubmit)="submit()" *ngIf="form.view">
        <div [innerHTML]="form.model.description"></div>

        <sf-form
            #fuelSystemForm
            name="form"
            (ngModelChange)="onModelChanged($event)"
            [view]="form.view"
            class="col-12"
            [(ngModel)]="form.model"
            [fields]="['name', 'fuelOptions', 'fuel', 'fuelBurnIncrement', 'fuelBurnIncrementReferenceRate', 'platformId']"
        ></sf-form>
        <div class="pe-4">
            <button type="submit" class="flex-shrink-1 btn btn-success ms-auto d-flex align-items-center">
                <i class="fa fa-fw fa-save mb-0 mr-1"></i> Save
            </button>
            <hr />
        </div>

        <h3>Fuel System Grid Layout</h3>
        <div *ngIf="form.view.children['fuelTankLayout'].children.length > 0">
            <div class="flex-container">
                <div *ngFor="let child of form.view.children['fuelTankLayout'].children; let i = index" class="flex-item">
                    <sf-form
                        #fuelSystemForm
                        name="form"
                        (ngModelChange)="onModelChanged($event)"
                        [view]="child"
                        class=""
                        [(ngModel)]="form.model.fuelTankLayout[i]"
                        [fields]="[
                            'fuelTank',
                            'html0',
                            'leftArrow',
                            'rightArrow',
                            'topArrow',
                            'bottomArrow',
                            'html1',
                            'leftSpace',
                            'rightSpace',
                            'topSpace',
                            'bottomSpace',
                            'html2',
                            'xFlow',
                            'xFlowDown',
                            'xFlowLines',
                            'xFlowHidden'
                        ]"
                    ></sf-form>
                </div>
            </div>
        </div>
        <div *ngIf="form.view.children['fuelTankLayout'].children.length <= 0">
            <p>Save the fuel system to set the fuel system grid</p>
        </div>

        <div class="pe-4 mt-2" *ngIf="form.view.children['fuelTankLayout'].children.length > 0">
            <button type="submit" class="flex-shrink-1 btn btn-success ms-auto d-flex align-items-center">
                <i class="fa fa-fw fa-save mb-0 mr-1"></i> Save
            </button>
            <hr />
        </div>
    </form>
</ng-container>

<ng-template #loadingOrError>
    <ng-container *ngIf="form.error; else loading">
        <small class="text-danger">Error loading form</small>
    </ng-container>

    <ng-template #loading>
        <small class="text-muted">Loading...</small>
    </ng-template>
</ng-template>
